<template>
  <div class="fs full-screen">
    <div class="fs-content">
      <h1 class="fs-title">Введите свой E-mail</h1>
      <p class="fs-desc">Введите свой E-mail, для последующего восстановления аккаунта, в случае утери мобильного телефона</p>
      <v-form v-model="valid" class="a-form">
        <div class="a-form-box">
          <TextInput
            label="E-mail"
            v-model="email"
            is-email
            required
          ></TextInput>
        </div>
      </v-form>

      <button
        class="fs-btn btn btn--blue"
        :disabled="loading || !valid"
        @click="submit"
      >

        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <template v-else>Продолжить</template>
      </button>

      <router-link
        :to="{ name: this.$routeName.ASSISTANCE }"
        class="fs-btn btn btn--white"
      >Пропустить</router-link>

    </div>
  </div>
</template>

<script>
import TextInput from "@component/UI/Form/TextInput";
import {mapActions} from "vuex";


export default {
  name: "ConfirmEmail",
  components: {
    TextInput,
  },
  data() {
    return {
      email: '',
      loading: false,
      valid: false
    };
  },
  methods: {
    ...mapActions('user', ['updateUserProfileData']),
    submit() {
      this.loading = true;
      this.updateUserProfileData({ user: { email: this.email }}).then(() => {
        this.$router.push({ name: this.$routeName.ASSISTANCE });
      }).finally(() => this.loading = false);
    }
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
  min-height: 800px;
  .fs-title {
    margin-bottom: 15px;
  }

  .fs-desc {
    margin-bottom: 13px;
  }
}

</style>
